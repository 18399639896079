// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  TestDataset,
  TestDatasetFromJSON,
  TestDatasetToJSON,
  TestDatasetCaseLinkPayload,
  TestDatasetCaseLinkPayloadFromJSON,
  TestDatasetCaseLinkPayloadToJSON,
  TestDatasetPayload,
  TestDatasetPayloadFromJSON,
  TestDatasetPayloadToJSON
} from "../models";

export interface CreateTestDatasetRequest {
  testDatasetPayload?: TestDatasetPayload;
}

export interface DeleteTestDatasetRequest {
  id: number;
  project: number;
}

export interface GetTestCaseDatasetsRequest {
  project: number;
  testcase: number;
}

export interface GetTestDatasetRequest {
  id: number;
  project: number;
}

export interface GetTestDatasetCountRequest {
  project: number;
  filter?: string;
}

export interface GetTestDatasetsRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface LinkTestCaseRequest {
  testDatasetCaseLinkPayload?: TestDatasetCaseLinkPayload;
}

export interface UpdateTestDatasetRequest {
  id: number;
  testDatasetPayload?: TestDatasetPayload;
}

/**
 * no description
 */
export class TestDatasetsApi extends runtime.BaseAPI {
  /**
   * Test dataset to add
   * Create a new test dataset
   */
  async createTestDatasetRaw(
    requestParameters: CreateTestDatasetRequest
  ): Promise<runtime.ApiResponse<TestDataset>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testdatasets`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestDatasetPayloadToJSON(requestParameters.testDatasetPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestDatasetFromJSON(jsonValue)
    );
  }

  /**
   * Test dataset to add
   * Create a new test dataset
   */
  async createTestDataset(
    requestParameters: CreateTestDatasetRequest
  ): Promise<TestDataset> {
    const response = await this.createTestDatasetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific test dataset
   * Delete test dataset
   */
  async deleteTestDatasetRaw(
    requestParameters: DeleteTestDatasetRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTestDataset."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteTestDataset."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testdatasets/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific test dataset
   * Delete test dataset
   */
  async deleteTestDataset(
    requestParameters: DeleteTestDatasetRequest
  ): Promise<any> {
    const response = await this.deleteTestDatasetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get test datasets linked to a test cae
   * Get test datasets linked to a test cae
   */
  async getTestCaseDatasetsRaw(
    requestParameters: GetTestCaseDatasetsRequest
  ): Promise<runtime.ApiResponse<Array<TestDataset>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCaseDatasets."
      );
    }

    if (
      requestParameters.testcase === null ||
      requestParameters.testcase === undefined
    ) {
      throw new runtime.RequiredError(
        "testcase",
        "Required parameter requestParameters.testcase was null or undefined when calling getTestCaseDatasets."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.testcase !== undefined) {
      queryParameters["testcase"] = requestParameters.testcase;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testdatasets/getLatest`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestDatasetFromJSON)
    );
  }

  /**
   * Get test datasets linked to a test cae
   * Get test datasets linked to a test cae
   */
  async getTestCaseDatasets(
    requestParameters: GetTestCaseDatasetsRequest
  ): Promise<Array<TestDataset>> {
    const response = await this.getTestCaseDatasetsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific test dataset
   * Get test dataset
   */
  async getTestDatasetRaw(
    requestParameters: GetTestDatasetRequest
  ): Promise<runtime.ApiResponse<TestDataset>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestDataset."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestDataset."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testdatasets/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestDatasetFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific test dataset
   * Get test dataset
   */
  async getTestDataset(
    requestParameters: GetTestDatasetRequest
  ): Promise<TestDataset> {
    const response = await this.getTestDatasetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of test datasets for specific project
   * Get test dataset count
   */
  async getTestDatasetCountRaw(
    requestParameters: GetTestDatasetCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestDatasetCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testdatasets/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of test datasets for specific project
   * Get test dataset count
   */
  async getTestDatasetCount(
    requestParameters: GetTestDatasetCountRequest
  ): Promise<Count> {
    const response = await this.getTestDatasetCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get test datasets specific to a project
   * Get test datasets
   */
  async getTestDatasetsRaw(
    requestParameters: GetTestDatasetsRequest
  ): Promise<runtime.ApiResponse<Array<TestDataset>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestDatasets."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testdatasets`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestDatasetFromJSON)
    );
  }

  /**
   * Get test datasets specific to a project
   * Get test datasets
   */
  async getTestDatasets(
    requestParameters: GetTestDatasetsRequest
  ): Promise<Array<TestDataset>> {
    const response = await this.getTestDatasetsRaw(requestParameters);
    return await response.value();
  }

  /**
   * link a test dataset to a test case
   * Link test dataset to test case
   */
  async linkTestCaseRaw(
    requestParameters: LinkTestCaseRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testdatasets/link`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestDatasetCaseLinkPayloadToJSON(
        requestParameters.testDatasetCaseLinkPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * link a test dataset to a test case
   * Link test dataset to test case
   */
  async linkTestCase(
    requestParameters: LinkTestCaseRequest
  ): Promise<BulkActionResult> {
    const response = await this.linkTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test dataset to update
   * Updates a test dataset
   */
  async updateTestDatasetRaw(
    requestParameters: UpdateTestDatasetRequest
  ): Promise<runtime.ApiResponse<TestDataset>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTestDataset."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testdatasets/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestDatasetPayloadToJSON(requestParameters.testDatasetPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestDatasetFromJSON(jsonValue)
    );
  }

  /**
   * Test dataset to update
   * Updates a test dataset
   */
  async updateTestDataset(
    requestParameters: UpdateTestDatasetRequest
  ): Promise<TestDataset> {
    const response = await this.updateTestDatasetRaw(requestParameters);
    return await response.value();
  }
}
