// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  ShareEntityPayload,
  ShareEntityPayloadFromJSON,
  ShareEntityPayloadToJSON,
  ShareEntityResult,
  ShareEntityResultFromJSON,
  ShareEntityResultToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  Token,
  TokenFromJSON,
  TokenToJSON,
  TokenMini,
  TokenMiniFromJSON,
  TokenMiniToJSON,
  TokenPayload,
  TokenPayloadFromJSON,
  TokenPayloadToJSON,
  ValidatePublicTokenResult,
  ValidatePublicTokenResultFromJSON,
  ValidatePublicTokenResultToJSON
} from "../models";

export interface DeleteTokenRequest {
  id: number;
}

export interface GenerateRestrictedTokenRequest {
  tokenPayload?: TokenPayload;
}

export interface GenerateTokenRequest {
  tokenPayload?: TokenPayload;
}

export interface ShareEntityRequest {
  shareEntityPayload?: ShareEntityPayload;
}

export interface ValidatePublicTokenRequest {
  publicToken?: string;
}

/**
 * no description
 */
export class TokensApi extends runtime.BaseAPI {
  /**
   * Deletes the specified token
   * Delete a token
   */
  async deleteTokenRaw(
    requestParameters: DeleteTokenRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteToken."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tokens/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes the specified token
   * Delete a token
   */
  async deleteToken(requestParameters: DeleteTokenRequest): Promise<any> {
    const response = await this.deleteTokenRaw(requestParameters);
    return await response.value();
  }

  /**
   * Generate restricted token
   * Create restricted token
   */
  async generateRestrictedTokenRaw(
    requestParameters: GenerateRestrictedTokenRequest
  ): Promise<runtime.ApiResponse<Token>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tokens/generateRestrictedToken`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TokenPayloadToJSON(requestParameters.tokenPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TokenFromJSON(jsonValue)
    );
  }

  /**
   * Generate restricted token
   * Create restricted token
   */
  async generateRestrictedToken(
    requestParameters: GenerateRestrictedTokenRequest
  ): Promise<Token> {
    const response = await this.generateRestrictedTokenRaw(requestParameters);
    return await response.value();
  }

  /**
   * Generate token
   * Create a new token
   */
  async generateTokenRaw(
    requestParameters: GenerateTokenRequest
  ): Promise<runtime.ApiResponse<Token>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tokens/generateToken`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TokenPayloadToJSON(requestParameters.tokenPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TokenFromJSON(jsonValue)
    );
  }

  /**
   * Generate token
   * Create a new token
   */
  async generateToken(requestParameters: GenerateTokenRequest): Promise<Token> {
    const response = await this.generateTokenRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get user tokens
   * Get all user tokens
   */
  async getMyTokensRaw(): Promise<runtime.ApiResponse<Array<TokenMini>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tokens/getMyTokens`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TokenMiniFromJSON)
    );
  }

  /**
   * Get user tokens
   * Get all user tokens
   */
  async getMyTokens(): Promise<Array<TokenMini>> {
    const response = await this.getMyTokensRaw();
    return await response.value();
  }

  /**
   * Share a specific entity public URL for example test plan
   * Share Public Token
   */
  async shareEntityRaw(
    requestParameters: ShareEntityRequest
  ): Promise<runtime.ApiResponse<ShareEntityResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tokens/shareEntityToken`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ShareEntityPayloadToJSON(requestParameters.shareEntityPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ShareEntityResultFromJSON(jsonValue)
    );
  }

  /**
   * Share a specific entity public URL for example test plan
   * Share Public Token
   */
  async shareEntity(
    requestParameters: ShareEntityRequest
  ): Promise<ShareEntityResult> {
    const response = await this.shareEntityRaw(requestParameters);
    return await response.value();
  }

  /**
   * Validate public token
   * Validate public token
   */
  async validatePublicTokenRaw(
    requestParameters: ValidatePublicTokenRequest
  ): Promise<runtime.ApiResponse<ValidatePublicTokenResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.publicToken !== undefined) {
      queryParameters["public_token"] = requestParameters.publicToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/tokens/validatePublicToken`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ValidatePublicTokenResultFromJSON(jsonValue)
    );
  }

  /**
   * Validate public token
   * Validate public token
   */
  async validatePublicToken(
    requestParameters: ValidatePublicTokenRequest
  ): Promise<ValidatePublicTokenResult> {
    const response = await this.validatePublicTokenRaw(requestParameters);
    return await response.value();
  }
}
